import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageContext } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Utils
import kebabCase from "lodash.kebabcase";

const Page = styled.div`
  margin: 120px 0;

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      width: fit-content;

      margin: 23px;

      & a {
        display: block;

        border: 1px solid #82a0aa;
        padding: 0 10px;

        text-transform: uppercase;

        color: #82a0aa;
        background-color: #fff;

        transition: 300ms color ease, 300ms background-color ease;

        font-size: 14px;
        line-height: 25px;

        &:hover {
          background-color: #82a0aa;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & ol {
      & li {
        margin: 10px;
      }
    }
  }
`;

const Artists = ({ data }) => {
  const [pageContext, setPageContext] = useContext(PageContext);

  useEffect(() => {
    setPageContext({
      pageTitle: `Artists`,
      pageColor: `#fff`,
      pageType: `page`,
    });
  }, []);

  const allItems = [
    ...data.allPrismicConversation.nodes,
    ...data.allPrismicCurating.nodes,
    ...data.allPrismicWriting.nodes,
  ];

  const allArtists = Array.from(
    new Set(
      allItems
        .map((artist) =>
          artist.data.artist_tag
            .filter((artist) => artist.artist !== null)
            .map((artist) => artist.artist)
        )
        .flat()
    )
  );

  const content = allArtists.map((artist, index) => (
    <li key={`single_artist_${index}`} className="tag">
      <Link to={`/${kebabCase(artist)}/`}>{artist}</Link>
    </li>
  ));

  return (
    <>
      <PageSeo
        seoTitle={`Artists - George Vasey`}
        seoText={null}
        seoImage={null}
      />
      <Page>
        <ol>{content}</ol>
      </Page>
    </>
  );
};

export const query = graphql`
  {
    allPrismicConversation {
      nodes {
        data {
          artist_tag {
            artist
          }
        }
      }
    }
    allPrismicCurating {
      nodes {
        data {
          artist_tag {
            artist
          }
        }
      }
    }
    allPrismicWriting {
      nodes {
        data {
          artist_tag {
            artist
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Artists);
